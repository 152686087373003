<template>
  <a-modal
    :visible="visible"
    :title="model.readOnly ? 'Lihat Segment' : form.id ? 'Edit Segment' : 'Tambahkan Segmen Baru'"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <template #footer>
      <a-button key="back" :hidden="model.readOnly" @click="handleCancel">Cancel</a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        :hidden="model.readOnly"
        @click="handleOk"
        >Submit</a-button
      >
    </template>
    <a-form :model="form" :rules="rules" class="myform" ref="formRef">
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Nama Segment"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            has-feedback
            name="name"
          >
            <a-input
              width="100%"
              placeholder="Input Nama Segment"
              v-model:value="name"
              :disabled="model.readOnly"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Deskripsi"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            has-feedback
            name="description"
          >
            <a-input
              width="100%"
              placeholder="Input Deskripsi Segment"
              v-model:value="description"
              :disabled="model.readOnly"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Status"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            name="active"
          >
            <a-radio-group :disabled="model.readOnly" v-model:value="active" required>
              <a-radio :value="true">Aktif</a-radio>
              <a-radio :value="false">Tidak Aktif</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'

export default {
  props: {
    visible: [Boolean],
    model: {
      type: [Object],
      default: () => ({
        active: null,
        id: null,
        name: null,
        description: null,
        record: {},
      }),
    },
  },
  emits: ['handleOk', 'update:visible'],
  setup(props, { emit }) {
    const form = reactive(Object.assign({}, props.model.record))

    const rules = {
      name: {
        required: true,
        message: 'Masukan Nama Segment',
      },
      active: {
        required: true,
        message: 'Status belum dipilih!',
        type: 'boolean',
      },
    }
    const formRef = ref('')
    const errorMessage = ref(null)

    const handleOk = async () => {
      try {
        await formRef.value.validate()
      } catch (error) {
        return
      }
      const url = `/api/vendor-types${form.id ? '/' + form.id + '?_method=PUT' : ''}`
      apiClient
        .post(url, form)
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          message.success('Berhasil menyimpan')
          emit('handleOk', form, true)
        })
        .catch(async error => {
          message.error('Gagal menyimpan')
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
    }
    const handleCancel = e => {
      emit('update:visible', false)
    }

    return {
      form,
      ...toRefs(form),
      emit,
      rules,
      handleOk,
      handleCancel,
      formRef,
      errorMessage,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
