export default [
  {
    title: 'No',
    slots: {
      customRender: 'no',
    },
  },
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Nama Segment',
    dataIndex: 'name',
  },

  {
    title: 'Status',
    dataIndex: 'status_text',
    // slots: { customRender: 'status' },
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
    align: 'center',
  },
]
